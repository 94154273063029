import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  matrixData: [],
};

const scoringMatrixDataSlice = createSlice({
  name: 'scoringMatrix',
  initialState,
  reducers: {
    setScoringMatrix: (state, action) => {
      state.matrixData = action.payload;
    },
  },
});

export const { setScoringMatrix } = scoringMatrixDataSlice.actions;

export default scoringMatrixDataSlice.reducer;
