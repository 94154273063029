import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { string, func } from 'prop-types';
import { map } from 'lodash';

/*const DonutChart = ({
  label = '',
  type = '',
  onEvent = () => {},
  dataList = {},
}) => {
  const [selectIndex, setSelectIndex] = useState(0);

  const colorsList = [
    '#00ffe5',
    '#075985',
    '#0284c3',
    '#0C4A6E',
    '#7DD3FC',
    '#0284c7',
    '#49a5e9',
  ];
  useEffect(() => {}, [selectIndex]);

  const onData = (value) => {
    setSelectIndex(value);
    onEvent(value);
  };

  const series = map(dataList, (data, index) => {
    return data?.count;
  });

  const labelList = map(dataList, (data, index) => {
    return data?.name;
  });

  const data = {
    states: {
      hover: {
        filter: 'none',
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: 'right',
      offsetY: 0,
      height: 200,
      show: false,
    },
    colors: [
      function ({ value, seriesIndex, dataPointIndex, w }) {
        if (selectIndex === seriesIndex) {
          return '#0284C7';
        } else {
          return colorsList[seriesIndex % colorsList.length];
        }
      },
    ],
    chart: {
      selection: {
        enabled: false,
      },
      events: {
        dataPointSelection: (event, chartContext, config) =>
          setTimeout(() => {
            onData(config?.dataPointIndex);
          }),
      },
      width: 100,
      type: 'donut',
    },
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'butt',
      width: 1,
      dashArray: 0,
    },
    dropShadow: {
      enabled: false,
      enabledOnSeries: false,
      top: 0,
      left: 0,
      blur: 3,
      color: '#000',
      opacity: 0.35,
    },
    tooltip: {
      fillSeriesColor: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    labels: labelList,
  };
  return (
    <div className="pie-wrap">
      {label ? <div className="grp-lbl">{label}</div> : null}
      <Chart
        id="pieFirst"
        options={data}
        series={series}
        labels={labelList}
        type={type ? type : 'pie'}
        width={200}
      />
    </div>
  );
};*/

const DonutChart = ({
  label = '',
  type = '',
  onEvent = () => {},
  dataList = {},
}) => {
  const [selectIndex, setSelectIndex] = useState(0);

  const colorsList = [
    '#00ffe5',
    '#075985',
    '#0284c3',
    '#0C4A6E',
    '#7DD3FC',
    '#0284c7',
    '#49a5e9',
  ];
  useEffect(() => {}, [selectIndex]);

  const onData = (value) => {
    setSelectIndex(value);
    onEvent(value);
  };

  const series = map(dataList, (data, index) => {
    return data?.count;
  });

  const labelList = map(dataList, (data, index) => {
    return data?.name;
  });

  const data = {
    states: {
      hover: {
        filter: 'none',
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: 'right',
      offsetY: 0,
      height: 200,
      show: false,
    },
    colors: [
      function ({ value, seriesIndex, dataPointIndex, w }) {
        if (selectIndex === seriesIndex) {
          return '#0284C7';
        } else {
          return colorsList[seriesIndex % colorsList.length];
        }
      },
    ],
    chart: {
      selection: {
        enabled: false,
      },
      events: {
        dataPointSelection: (event, chartContext, config) =>
          setTimeout(() => {
            onData(config?.dataPointIndex);
          }),
      },
      width: 100,
      type: 'donut',
    },
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'butt',
      width: 1,
      dashArray: 0,
    },
    dropShadow: {
      enabled: false,
      enabledOnSeries: false,
      top: 0,
      left: 0,
      blur: 3,
      color: '#000',
      opacity: 0.35,
    },
    tooltip: {
      fillSeriesColor: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    labels: labelList,
  };
  return (
    /*<div className="pie-wrap">
      {label ? <div className="grp-lbl">{label}</div> : null}
      <Chart
        id="pieFirst"
        options={data}
        series={series}
        labels={labelList}
        type={type ? type : 'pie'}
        width={200}
      />
    </div>*/
    <div className="">
      {label ? <div className="grp-lbl">{label}</div> : null}
      <div className="pie-wrap">
        <Chart options={data} series={series} type="donut" width="250" />
      </div>
    </div>

    /*
    <div className="pacing-item edit-form scroll edit-form-wrap d-flex flex-column justify-content-between">
      <div className="pacing-title">
        <div class="itemBtnExpanded itemBtn d-flex   align-items-center justify-content-between">
          <div class="form-icon align-items-center">
            <span class="menuLblHead">
              {label ? <div className="grp-lbl">{label}</div> : null}
            </span>
          </div>
        </div>
      </div>
      <div className="accordion-content allocation-snapshot">
        <div>
          <Chart
            id="pieFirst"
            options={data}
            series={series}
            labels={labelList}
            type={type ? type : 'pie'}
            width={200}
          />
        </div>
      </div>
    </div>*/
  );
};

DonutChart.propTypes = {
  label: string,
  type: string,
  onEvent: func,
};

export default DonutChart;
