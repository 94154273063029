const APPLICATION_NAME = 'YOUR_APPLICATION_NAME';
const baseAuth = 'amt1QDhlYWtoUzlTOjhyUzBnU0coVDQwKTlDaA==';

const currencySymbols = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
};

const languages = {
  english: 'ENG',
  hindi: 'HI',
};

const defaultFundField = [
  {
    value: 'fund-details.inception_year',
    label: 'Inception Year',
  },
  {
    value: 'fund-details.size',
    label: 'Size',
  },
  {
    value: 'fund-details.max_fund_size',
    label: 'Max Size',
  },
  {
    value: 'fund-terms.investment_period',
    label: 'Investment Period',
  },
  {
    value: 'fund-terms.fund_duration',
    label: 'Duration',
  },
  {
    value: 'fund-terms.extension',
    label: 'Extension',
  },
  {
    value: 'fund-fees.Management Fee',
    label: 'Management Fee',
  },
  {
    value: 'fund-terms.carried_interest',
    label: 'Carried Interest',
  },
  {
    value: 'fund-terms.preferred_return',
    label: 'Preferred Return',
  },
  {
    value: 'fund-terms.gp_commitment',
    label: 'GP Commitment',
  },
];

const reviewStatus = ['Initial Review', 'Data Extraction', 'Pending Review'];
const TOKEN_KEY = 'token';

export {
  APPLICATION_NAME,
  languages,
  baseAuth,
  currencySymbols,
  TOKEN_KEY,
  reviewStatus,
  defaultFundField,
};
