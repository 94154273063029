import fetchFromApiServer from 'services/api';

export function fetchDueDiligenceFunds(requestObject) {
  const url = `/landing-page/due-diligence-funds`;
  return fetchFromApiServer('GET', url, requestObject);
}

export function fetchCommittedActiveFunds(requestObject) {
  const url = `/landing-page/committed-active-funds`;
  return fetchFromApiServer('GET', url, requestObject);
}

export function fetchPendingReviewFunds(requestObject) {
  const url = `/landing-page/pending-review-funds`;
  return fetchFromApiServer('GET', url, requestObject);
}

export function fetchRecentClosedFunds(requestObject) {
  const url = `/landing-page/recent-closed-funds`;
  return fetchFromApiServer('GET', url, requestObject);
}

export function fetchRecentReferences(requestObject) {
  const url = `/landing-page/recent-references`;
  return fetchFromApiServer('GET', url, requestObject);
}
