import React from 'react';
import classNames from 'classnames';
import './styles.css';

const TextArea = ({
  placeholder,
  onChange,
  value = '',
  required,
  disabled,
  handleBlur = () => {},
  ...rest
}) => {
  const onInputChange = ({ target }) => {
    onChange && onChange(target.value, target.name);
  };

  return (
    <div className="input-block">
      <textarea
        id="text-area"
        rows="4"
        onChange={onInputChange}
        value={value}
        placeholder={placeholder}
        className={classNames('className', {
          'input-disable': disabled,
        })}
        onBlur={handleBlur()}
        {...rest}
      />
      {/* {placeholder ? (
        <span
          className={classNames('placeholder', {
            'required ': required,
          })}
        >
          {placeholder}
        </span>
      ) : null} */}
    </div>
  );
};
export default TextArea;
