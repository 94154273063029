import React, { useEffect, useState } from 'react';
import PdfViewer from './PdfViewer';
import { useParams } from 'react-router-dom';
import { setFundData } from '../Slice/FundDataSlice';
import { getfundDetails } from '../Slice/FundSlice';
import { useDispatch } from 'react-redux';
import { Loader } from 'components';
const PdfViewerData = () => {
  const [isLoading, setLoading] = useState(false);
  const [fundCompareData, setFundCompareData] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    try {
      setLoading(true);
      dispatch(getfundDetails(id)).then((res) => {
        if (res?.payload?.status === 200) {
          const fundDataRes = res?.payload?.data?.data;
          dispatch(setFundData(fundDataRes));
        }
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : null}

      <PdfViewer
        fundKey={id}
        fundCompareData={fundCompareData}
        directCall={1}
      />
    </>
  );
};
export default PdfViewerData;
