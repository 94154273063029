import { fetchFundManagers } from './fundManagerActions';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Create async thunks for fetching data with error handling
export const getFundManagers = createAsyncThunk(
  'GET_FUND_MANAGERS',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchFundManagers();

      if (response.status !== 200) {
        // Handle non-200 responses by returning a custom error payload
        return rejectWithValue({
          status: response.status,
          error: response.data?.error || 'Failed to fetch due diligence funds',
        });
      }

      return response.data; // Success, return the data
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      return rejectWithValue({
        status: error.response?.status || 500,
        error:
          error.message ||
          'Unknown error occurred while fetching due diligence funds',
      });
    }
  }
);

const initialState = {
  fundManagers: [],
  fundManagerColumns: [],
};

export const fundManagerPageSlice = createSlice({
  name: 'fundManagerPage',
  initialState,
  reducers: {
    setFundManagers: (state, action) => {
      state.fundManagers = action.payload;
    },
    setFundManagerColumns: (state, action) => {
      state.fundManagerColumns = action.payload;
    },
  },
});

// Export actions
export const { setFundManagers, setFundManagerColumns } =
  fundManagerPageSlice.actions;

// Export the reducer
export default fundManagerPageSlice.reducer;
