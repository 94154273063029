import { Fragment } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const Tooltip = ({ id, children, label, delay = '', place = 'top' }) => {
  return (
    <Fragment key={id}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a data-tooltip-delay-show={delay} data-tooltip-id={id}>
        {label}
      </a>
      <ReactTooltip className="custom-tooltip" id={id} place={place}>
        {children}
      </ReactTooltip>
    </Fragment>
  );
};

export default Tooltip;
Tooltip.defaultProps = {
  id: 'tool-tip',
  place: 'top',
};
