import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './redux/store/configureStore';
import { Routing } from 'router';
import apiHandler from './services/interceptor/index';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  // Memoize the persistor to avoid recreating it on every render
  const persistor = useMemo(() => persistStore(store), []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routing />
      </PersistGate>
    </Provider>
  );
};

export default apiHandler(App);
