import React from 'react';
import { any, node } from 'prop-types';
import { Root, Portal, Trigger, Content, Arrow } from '@radix-ui/react-popover';
import './styles.css';
import { CloseIcon } from 'assets';

const Popover = ({
  button = <button>Open</button>,
  children,
  buttonRef,
  contentStyle,
  className,
  Onopen,
  onOpenChange,
  handleCancelClick,
}) => (
  <Root open={Onopen} onOpenChange={onOpenChange}>
    <Trigger asChild ref={buttonRef} className={className}>
      {button}
    </Trigger>
    <Portal>
      <div className="popover-wrap">
        <Content
          className={
            contentStyle ? `${contentStyle} PopoverContent` : 'PopoverContent'
          }
          sideOffset={5}
          data-side="left"
        >
          <span onClick={handleCancelClick} className="d-none">
            <img src={CloseIcon} alt="" />
          </span>
          {children}

          <Arrow className="PopoverArrow" />
        </Content>
      </div>
    </Portal>
  </Root>
);

Popover.propTypes = {
  button: any,
  children: node,
};
export default Popover;
