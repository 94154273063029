import React, { useEffect, useState } from 'react';
import { DocumentIcon, EditIcon, RightArrow } from 'assets';
import Table from 'components/Grid';
import { useSelector } from 'react-redux';
import TableSkeleton from 'components/skeleton/TableSkeleton';
import ScoringArray from '../components/ScoringArray';
import ReactMarkdown from 'react-markdown';
import { getJsonValue } from 'services/utils';

const PdfScoring = ({ fundData }) => {
  const [scoringData, setScoringData] = useState(
    useSelector((state) => state.scoringMatrix.matrixData)
  );
  const [columnList, setColumnList] = useState([]);
  const [visibleData, setVisibleData] = useState([]);

  const renderMarkdown = (text) => (
    <ReactMarkdown
      components={{
        p: ({ node, ...props }) => <span {...props} />,
      }}
    >
      {text}
    </ReactMarkdown>
  );

  const formatScoringMatrix = (data) => {
    let ret = [];
    console.log('data = ', data);
    data.forEach((value, rowIndex) => rowGroupComponent(value, rowIndex, ret));
    return ret;
  };
  const individualRowComponent = (value, index, rowIndex, ret) => {
    const index_name = Object.keys(value)[0];
    const parseValue = value[index_name];
    ret.push({
      name: renderMarkdown(parseValue.displayName),
      score: (
        <ScoringArray
          current={parseValue.value}
          rowIndex={rowIndex}
          colIndex={index}
          changeScore={null}
          editJson={scoringData}
        />
      ),
      rationale: renderMarkdown(parseValue.description),
    });
  };
  const rowGroupComponent = (value, rowIndex, ret) => {
    console.log('&&&');
    const key = Object.keys(value)[0];
    const rowKey = Object.keys(scoringData[rowIndex])[0];
    const parseValue = value[key];
    ret.push({
      name: renderMarkdown(value[key].displayName),
      score: (
        <ScoringArray
          current={scoringData[rowIndex][rowKey].value}
          rowIndex={rowIndex}
          colIndex={-1}
          changeScore={null}
          editJson={scoringData}
        />
      ),
      rationale: renderMarkdown(scoringData[rowIndex][rowKey].description),
    });
    value[key]['sub-scores'].forEach((value, index) =>
      individualRowComponent(value, index, rowIndex, ret)
    );
  };

  useEffect(() => {
    const fundDetailsData = scoringData;
    if (fundDetailsData) {
      const dynamicColumns = [
        {
          Header: '',
          accessor: 'name',
        },
        {
          Header: 'Score',
          accessor: 'score',
        },
        {
          Header: 'Rationale',
          accessor: 'rationale',
        },
      ];
      const formattedScoringMatrix = formatScoringMatrix(scoringData);
      setColumnList(dynamicColumns);
      setVisibleData(formattedScoringMatrix);
    }
  }, []);

  const hasAnyValue = (obj) =>
    Object.values(obj).some(
      (value) =>
        value !== '' &&
        value !== undefined &&
        value !== null &&
        value !== 'null'
    );

  return (
    <Table
      allowAdd
      allowExport
      onAdd={alert}
      data={visibleData}
      isLoading={false}
      columns={columnList}
      customSorting={false}
      customPagination={false}
      addButtonName="Add User"
      // scrollStyle="scrollable-fund scroll fix-height"
      ContainerClass="table-container-fund"
    />
  );
};

export default PdfScoring;
