import React, { useEffect, useState, useRef } from 'react';
import { IoChevronDown, IoChevronForward } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { CustomButton, Modal, Tooltip } from 'ui-controls';
import { caution } from 'assets';
import { reviewStatus } from 'services/constants';
import { useNavigate } from 'react-router-dom';
import { current } from '@reduxjs/toolkit';

const DocumentTypeDropdown = ({
  docIndex,
  mainIndex,
  setDocumentType,
  docConfig,
  selectedType,
  documentOrData = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [configList, setConfigList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(selectedType);
  let dropdown = useRef();
  const getAllKeys = (json) => {
    const keys = ['Uncategorized'];
    if (
      json.FundDocClassification &&
      Array.isArray(json.FundDocClassification)
    ) {
      json.FundDocClassification.forEach((classification) => {
        console.log('class = ', classification);
        let [key, values] = Object.entries(classification).find(([k, v]) =>
          Array.isArray(v)
        );
        if (key && classification.llmprocessing == documentOrData) {
          console.log('key pushed, key = ', key);
          keys.push(key);
        }
      });
    }
    return keys;
  };

  useEffect(() => {
    let handler = (e) => {
      if (dropdown.current && !dropdown.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
  });

  useEffect(() => {
    console.log('docConfig = ', docConfig);
    setConfigList(getAllKeys(docConfig));
    console.log('selectedtype = ', selectedType);
    setSelectedOption(selectedType);
    setCurrentIndex(configList.findIndex((type) => type === selectedType));
  }, [mainIndex, selectedType]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setCurrentIndex(configList.indexOf(option));
    setDocumentType({ index: mainIndex ?? docIndex, newType: option });
    setIsOpen(false);
  };
  const DropdownItem = ({ option }) => (
    <div className="menuItem" onClick={() => handleOptionClick(option)}>
      {option}
    </div>
  );

  const toggleDropdown = () => {
    console.log('configList = ', configList);
    if (configList?.length > 0) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className="fundLabel-checkmark-wrapper">
      <div className="fundDocument-wrapper" ref={dropdown}>
        <div
          className="inputStyle dropdownWidth spaceBetween"
          onClick={toggleDropdown}
        >
          <div className="fundDropdown-text">
            <Tooltip
              delay={500}
              id={`tooltip-selected-${docIndex}`}
              label={<div>{selectedOption}</div>}
            >
              {selectedOption ? selectedOption : ''}
            </Tooltip>
          </div>
          <div className="trailing-icon">
            {configList?.length > 1 &&
              (isOpen ? <IoChevronDown /> : <IoChevronForward />)}
          </div>
        </div>
        {isOpen ? (
          <div
            className={`${!documentOrData ? 'fundDropdown-menu-auto' : ''} fundDropdown-menu scrollable`}
          >
            {configList.map((option, index) => (
              <DropdownItem option={option} key={index} />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DocumentTypeDropdown;
