import React, { useEffect, useState } from 'react';

export default function ScoringArray({
  current,
  rowIndex,
  colIndex,
  changeScore = null,
  editJson,
}) {
  const [selected, setSelected] = useState(current);
  const updateState = (index) => {
    setSelected(index);
    changeScore({
      rowIndex: rowIndex,
      index: colIndex,
      value: index,
    });
  };
  const rowKey = Object.keys(editJson[rowIndex])[0];
  const scoringArray = editJson[rowIndex][rowKey]['scoring-metric'];
  const circles = () => {
    // Create an array of circles
    return (
      <div className="circles-container flex">
        {scoringArray.map((scoringElement, index) => {
          // Determine the color of each circle
          const shapeClass =
            scoringElement.toString().length > 1 ? 'oval ' : 'circle ';
          const isActive = scoringElement === selected;
          const circleClass = isActive ? 'active' : 'inactive';

          return (
            <div
              key={index}
              className={shapeClass + circleClass}
              onClick={() => {
                if (changeScore != null) {
                  updateState(scoringElement);
                }
              }}
            >
              {scoringElement}
            </div>
          );
        })}
      </div>
    );
  };
  return circles();
}
