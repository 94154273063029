import { useRef } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const DatePickerBox = ({
  id,
  onChange,
  isDisable,
  className,
  value,
  placeholder,
  label,
  infoTxt,
  ...rest
}) => {
  const ref = useRef();
  const { isLanguage } = useSelector((state) => state.login);

  const inputClass = classNames(className, {
    'inputStyle dateInput': true,
    'input-disabled': isDisable,
  });

  const lablStyle = classNames(className, {
    lblTxt: true,
    'lblTxt-REV': isLanguage === 'ARB' ? true : false,
  });

  return (
    <div>
      {label ? <div className={lablStyle}>{label} </div> : null}
      <input
        id="date"
        ref={ref}
        type="date"
        value={value}
        disabled={isDisable}
        className={inputClass}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        onBlur={() => (ref.current.type = 'date')}
        onFocus={() => (ref.current.type = 'date')}
        {...rest}
      />
      {infoTxt ? <div className="infTxt">{infoTxt} </div> : null}
    </div>
  );
};

DatePickerBox.defaultProps = {
  id: 'priButton',
  name: 'mainName',
};

export default DatePickerBox;
