const Checkbox = ({
  id,
  placeholder,
  isChecked = false,
  onChange,
  disabled,
  ...rest
}) => {
  return (
    <div className="">
      <input
        id={id}
        type="checkbox"
        onChange={() => {}}
        checked={isChecked}
        onClick={(e) => {
          onChange?.(!isChecked);
        }}
        disabled={disabled}
        {...rest}
      />
      <label
        htmlFor={id}
        onClick={
          disabled
            ? () => {}
            : (e) => {
                e.preventDefault();
                onChange?.(!isChecked);
              }
        }
        style={{ cursor: 'pointer' }}
      >
        {placeholder}
      </label>
    </div>
  );
};

export default Checkbox;
