import classNames from 'classnames';
import { useSelector } from 'react-redux';

const MobileBox = ({
  id,
  onChange,
  isDisable,
  className,
  value,
  placeholder,
  label,
  infoTxt,
  ...rest
}) => {
  const { isLanguage } = useSelector((state) => state.login);

  const inputClass = classNames(className, {
    mobileStyle: true,
    'input-disabled': isDisable,
    'Txt-REV num-pd': isLanguage === 'ARB' ? true : false,
  });

  const lablStyle = classNames(className, {
    lblTxt: true,
    'lblTxt-REV': isLanguage === 'ARB' ? true : false,
  });

  return (
    <div style={{ position: 'relative' }}>
      {label ? <div className={lablStyle}>{label} </div> : null}
      <input
        key={id}
        type="number"
        disabled={isDisable}
        onChange={(e) => onChange(e)}
        className={inputClass}
        value={value}
        placeholder={placeholder}
        {...rest}
      />
      {infoTxt ? <div className="infTxt">{infoTxt} </div> : null}
    </div>
  );
};

MobileBox.defaultProps = {
  id: 'priButton',
  name: 'mainName',
};

export default MobileBox;
