import axios from 'axios';
import { getToken, setToken } from 'services/utils';
import { baseAuth } from 'services/constants';

const fetchFromApiServer = (requestType, url, data, options) => {
  return fetchApiWrapper(url, requestType, data, options);
};

function getHeaderConfig(requestType, options) {
  const token = getToken();
  const config = {
    headers: {
      'Content-Type':
        requestType === 'MULTIPART'
          ? 'multipart/form-data'
          : 'application/json',
      authorization: 'Bearer ' + (token || baseAuth),
      Accept: '*/*',
    },
    params: { ...options },
    timeout: 60 * 10 * 1000,
  };
  //console.log('& config = ', config);
  return config;
}

const fetchApiWrapper = async (uri, requestType, data, options = {}) => {
  const url = process.env.REACT_APP_API_URL + uri;
  const config = getHeaderConfig(requestType, options);
  let response = null;
  if (requestType === 'GET') {
    response = await axios({ url, method: 'get', ...config });
  } else if (requestType === 'POST') {
    response = await axios({ url, method: 'post', data, ...config });
  } else if (requestType === 'DELETE') {
    response = await axios({ url, method: 'delete', data, ...config });
  } else if (requestType === 'PUT') {
    response = await axios({ url, method: 'put', data, ...config });
  } else if (requestType === 'PATCH') {
    response = await axios({ url, method: 'patch', data, ...config });
  } else if (requestType === 'MULTIPART') {
    response = await axios({ url, method: 'post', data, ...config });
  } else if (requestType === 'JSON') {
    response = await axios.get(url);
  }
  const token = response?.data?.user_token || null;
  const status = response?.status;
  //console.log('Response:', response);
  //console.log('token = ', token);
  if (token != null) {
    setToken(token); // Set our stored user token to one returned by server
  }
  return response;
};

export default fetchFromApiServer;
