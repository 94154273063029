import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { languages } from 'services/constants';
import { setToken } from 'services/utils';
import { validateLogin } from './loginActions';

const initialState = {
  status: 'idle',
  isLoggedIn: false,
  language: languages.english,
  userData: [],
};

export const fetchLogin = createAsyncThunk(
  'VALIDATE_LOGIN',
  async (requestObject) => {
    const data = await validateLogin(requestObject);
    //console.log('& data = ', data.headers.get('Authorization'));
    return { data: data.data, status: data.status };
  }
);

export const validateUserLogin = createAction('VALIDATE_USER_LOGIN');
export const changeLanguage = createAction('CHANGE_LANGUAGE');

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.userData = [];
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(validateUserLogin, (state) => {
      state.isLoggedIn = true;
      setToken('xx5542542fdgfdgggsfdgfdgf');
    });

    builder
      .addCase(changeLanguage, (state, { payload }) => {
        state.language = payload;
      })

      .addCase(fetchLogin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLogin.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.userData = payload?.data;
        state.isLoggedIn = true;
        //console.log('& payload = ', payload);
        //console.log('& payload header = ', payload.headers);
        setToken(payload?.data.token);
      })
      .addCase(fetchLogin.rejected, (state) => {
        state.status = 'failed';
      });
  },
});
export const { logout } = loginSlice.actions;

export const selectUserLoggedIn = (state) => state.login.isLoggedIn;
export const selectIsLoading = (state) => state.login.status === 'loading';

export default loginSlice.reducer;
