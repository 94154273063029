import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TableSkeleton.css'; // Import the custom CSS for skeleton styles

const TableSkeleton = () => {
  return (
    <div className="container mt-3">
      <div className="skeleton-header mb-2"></div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className="skeleton-header-cell skeleton-block"></th>
              <th className="skeleton-header-cell skeleton-block"></th>
              <th className="skeleton-header-cell skeleton-block"></th>
            </tr>
          </thead>
          <tbody>
            {[...Array(5)].map((_, index) => (
              <tr key={index}>
                <td className="skeleton-cell skeleton-block"></td>
                <td className="skeleton-cell skeleton-block"></td>
                <td className="skeleton-cell skeleton-block"></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableSkeleton;
