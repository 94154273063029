import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorker from './services/sw/serviceWorker';
import reportWebVitals from './services/web-vital/reportWebVitals';
import './style/index.css';
import './style/responsive.css';
import './style/styles.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <React.Fragment>
      <ToastContainer
        hideProgressBar={true}
        autoClose={10000}
        pauseOnFocusLoss
        closeOnClick
      />
      <App />
    </React.Fragment>
  </React.StrictMode>
);

// Start measuring performance, pass a function to log results (for example: reportWebVitals(console.log)) : https://bit.ly/CRA-vitals
if (process.env.REACT_APP_WEB_VITAL === 'true') {
  reportWebVitals(console.log);
} else {
  console.warn(
    'Web Vitals are disabled. Set REACT_APP_WEB_VITAL to "true" to enable.'
  );
}

// Change unregister() to register() to start service worker : https://bit.ly/CRA-PWA
if (process.env.REACT_APP_ENABLE_SW === 'true') {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
