import React, { useState } from 'react';
import { Logo } from '../../assets';
import Chart from 'react-apexcharts';
import { values } from 'lodash';

const BarChart = ({
  label,
  categories,
  values,
  horizontal,
  formatter = null,
  yAxis,
  generateColors,
}) => {
  const colors = generateColors(values.length);
  const formatterVal = formatter ? formatter : null;
  console.log('categories = ', categories);
  console.log('values = ', values);
  const data = {
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: yAxis ? yAxis : '',
      },
      labels: {
        formatter: (value) => (formatterVal ? formatterVal(value) : value),
      },
    },
    chart: {
      // height: 380,
      type: 'bar',
      redrawOnParentResize: true,
    },
    plotOptions: {
      bar: {
        horizontal: horizontal,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
      ...(formatterVal && { formatter: formatterVal }),
      style: {
        colors: ['#ffffff'],
      },
      background: {
        enabled: true,
        foreColor: '#000',
        borderRadius: 2,
        padding: 4,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: '#000',
      },
    },
    colors: colors,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: 'right',
      offsetY: 0,
      height: 200,
      show: false,
    },
    tooltip: {
      enabled: true,
      y: {
        ...(formatterVal && { formatter: formatterVal }),
      },
    },
  };

  const series = [
    {
      name: '',
      data: values,
    },
  ];
  return (
    <div className="bar-wrap fix-height">
      {label ? <div className="grp-lbl">{label}</div> : null}
      <Chart type="bar" height={'300'} options={data} series={series} />
    </div>
  );
};

export default BarChart;
