import fetchFromApiServer from 'services/api';

export function addFundDetails(requestObject) {
  const url = `funds/fund-add`;
  return fetchFromApiServer('POST', url, requestObject);
}

export function updateFundDetails(requestObject) {
  const url = `funds/fund-update`;
  return fetchFromApiServer('PUT', url, requestObject);
}

export function updatefundHistory(requestObject) {
  const url = `funds/change-history-log`;
  return fetchFromApiServer('POST', url, requestObject);
}

export function updateFundStatus(requestObject) {
  const url = `funds/update-fund-status`;
  return fetchFromApiServer('POST', url, requestObject);
}

export function updateRefudEditId(requestObject) {
  const url = `funds/fund-details/${requestObject}`;
  return fetchFromApiServer('GET', url, requestObject);
}

export function apiChat(requestObject) {
  const url = `chat`;
  return fetchFromApiServer('POST', url, requestObject);
}

export function fetchImageDetails(requestObject) {
  const url = `funds/image`;
  return fetchFromApiServer('POST', url, requestObject);
}

export function updateAIFund(requestObject) {
  const url = `funds/save-ai-fund`;
  return fetchFromApiServer('PUT', url, requestObject);
}
