import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchHomeData,
  fetchTableData,
  fetchFundList,
  uploadFundDoc,
  uploadDataDoc,
  getDocNotification,
  updateNotificationStatus,
  getDocConfig,
  postDocConfig,
  getSpreadSheet,
  uploadFundPresign,
  sendEmailNotifications,
} from './homeActions';
import {
  addFundDetails,
  updateFundDetails,
  updateRefudEditId,
  updateFundStatus,
  updatefundHistory,
  apiChat,
  updateAIFund,
  fetchImageDetails,
} from './fundActions';
import {
  fetchCompareFundData,
  fetchCompareFundField,
  fetchUpdateCompareFundData,
} from 'modules/fund/Slice/FundActions';

const initialState = {
  status: 'idle',
  intervalRef: null,
  homeData: {},
  ByFundStatus: [],
  ByFundManager: [],
  ByAssetClass: [],
  documentConfig: {},
};

export const getHomeData = createAsyncThunk(
  'GET_HOME_DATA',
  async (requestObject) => {
    const { data, status } = await fetchHomeData(requestObject);
    console.log('###### getHomeData = ', { data, status });
    return { data, status };
  }
);

export const getFundList = createAsyncThunk(
  'GET_FUND_LIST',
  async (requestObject) => {
    const { data, status } = await fetchFundList(requestObject);
    return { data, status };
  }
);

export const getAddFundDetails = createAsyncThunk(
  'GET_FUND_DETAILS',
  async (requestObject) => {
    const { data, status } = await addFundDetails(requestObject);
    return { data, status };
  }
);

export const getUpdateFundDetails = createAsyncThunk(
  'UPDATE_FUND_DETAILS',
  async (requestObject) => {
    const { data, status } = await updateFundDetails(requestObject);
    return { data, status };
  }
);

export const updateHistoryLog = createAsyncThunk(
  'UPDATE_FUND_HISTORY',
  async (requestObject) => {
    const { data, status } = await updatefundHistory(requestObject);
    return { data, status };
  }
);

export const getRefundEditById = createAsyncThunk(
  'REFUND_EDIT_DETAILS',
  async (requestObject) => {
    const { data, status } = await updateRefudEditId(requestObject);
    return { data, status };
  }
);

export const updateFundStatusByID = createAsyncThunk(
  'UPDATE_FUND_STATUS',
  async (requestObject) => {
    const data = await updateFundStatus(requestObject);
    return { data: data.data };
  }
);

export const getTableData = createAsyncThunk(
  'GET_TABLE_DATA',
  async (requestObject) => {
    const { data, status } = await fetchTableData(requestObject);
    console.log('GET TABLE DATA = ', { data, status });
    return { data, status };
  }
);

export const getFundCompareData = createAsyncThunk(
  'GET_FUND_COMPARE_DATA',
  async (requestObject) => {
    const { data, status } = await fetchCompareFundData(requestObject);
    return { data, status };
  }
);

export const updateFundCompareData = createAsyncThunk(
  'UPDATE_FUND_COMPARE_DATA',
  async (requestObject) => {
    const { data, status } = await fetchUpdateCompareFundData(requestObject);
    return { data, status };
  }
);

export const getFundCompareFields = createAsyncThunk(
  'GET_FUND_COMPARE_FIELDS',
  async (requestObject) => {
    const { data, status } = await fetchCompareFundField(requestObject);
    return { data, status };
  }
);

export const fundUpload = createAsyncThunk(
  'FUNDS_UPLOAD',
  async (requestObject) => {
    const data = await uploadFundDoc(requestObject);
    return { data: data.data, status: data.status };
  }
);

export const getFundUploadPresign = createAsyncThunk(
  'PRESIGN_FUND_UPLOAD',
  async (requestObject) => {
    const data = await uploadFundPresign(requestObject);
    return { data: data.data, status: data.status };
  }
);

export const dataUpload = createAsyncThunk(
  'DATA_UPLOAD',
  async (requestObject) => {
    const data = await uploadDataDoc(requestObject);
    return { data: data.data, status: data.status };
  }
);

export const getApiChat = createAsyncThunk(
  'API_CHAT',
  async (requestObject) => {
    const { data, status } = await apiChat(requestObject);
    return { data, status };
  }
);

export const getImageDetails = createAsyncThunk(
  'IMAGE_RESPONSE',
  async (requestObject) => {
    const { data, status } = await fetchImageDetails(requestObject);
    return { data, status };
  }
);

export const updateFundByAI = createAsyncThunk(
  'SAVE_AI_FUND',
  async (requestObject) => {
    const { data, status } = await updateAIFund(requestObject);
    return { data, status };
  }
);

export const getConfigJSON = createAsyncThunk(
  'GET_DOC_CONFIG',
  async (requestObject) => {
    const data = await getDocConfig(requestObject);
    return data;
  }
);

export const getSpreadSheetXLSX = createAsyncThunk(
  'GET_DOC_CONFIG',
  async (requestObject) => {
    const data = await getSpreadSheet(requestObject);
    return data;
  }
);

export const uploadConfigJSON = createAsyncThunk(
  'UPLOAD_DOC_CONFIG',
  async (requestObject) => {
    const data = await postDocConfig(requestObject);
    return data;
  }
);

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setDocumentConfig: (state, action) => {
      state.documentConfig = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomeData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getHomeData.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.homeData = payload?.data;
        state.ByFundStatus = payload?.data?.byStatus;
        state.ByFundManager = payload?.data?.byManager;
        state.ByAssetClass = payload?.data?.byAsset;
      })
      .addCase(getHomeData.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const docNotification = createAsyncThunk(
  'DOCUMENT_STATUS',
  async ({ user_id, group_id }) => {
    const data = await getDocNotification(user_id, group_id);
    return { data: data.data };
  }
);

export const sendEmailNotification = createAsyncThunk(
  'EMAIL_NOTIFICATION',
  async ({ user_id, fund_id }) => {
    const data = await sendEmailNotifications({ user_id, fund_id });
    return { data: data.data };
  }
);

export const updateDocNotificationStatus = createAsyncThunk(
  'UPDATE_DOC_NOTIFICATION_STATUS',
  async (requestObject) => {
    const data = await updateNotificationStatus(requestObject);
    return { data: data.data };
  }
);
export const selectDocumentConfig = (state) => state.home.documentConfig;
export const { setDocumentConfig } = homeSlice.actions;
export const selectIsLoading = (state) => state.home.status === 'loading';
export default homeSlice.reducer;
