import {
  fetchDueDiligenceFunds,
  fetchPendingReviewFunds,
  fetchRecentClosedFunds,
  fetchRecentReferences,
  fetchCommittedActiveFunds,
} from './landingPageActions';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Create async thunks for fetching data with error handling
export const getDueDiligenceFunds = createAsyncThunk(
  'GET_DUE_DILIGENCE_FUNDS',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchDueDiligenceFunds();

      if (response.status !== 200) {
        // Handle non-200 responses by returning a custom error payload
        return rejectWithValue({
          status: response.status,
          error: response.data?.error || 'Failed to fetch due diligence funds',
        });
      }

      return response.data; // Success, return the data
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      return rejectWithValue({
        status: error.response?.status || 500,
        error:
          error.message ||
          'Unknown error occurred while fetching due diligence funds',
      });
    }
  }
);

export const getCommittedActiveFunds = createAsyncThunk(
  'GET_DUE_DILIGENCE_FUNDS',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchCommittedActiveFunds();

      if (response.status !== 200) {
        // Handle non-200 responses by returning a custom error payload
        return rejectWithValue({
          status: response.status,
          error:
            response.data?.error || 'Failed to fetch committed/active funds',
        });
      }

      return response.data; // Success, return the data
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      return rejectWithValue({
        status: error.response?.status || 500,
        error:
          error.message ||
          'Unknown error occurred while fetching due diligence funds',
      });
    }
  }
);

export const getPendingReviewFunds = createAsyncThunk(
  'GET_PENDING_REVIEW_FUNDS',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchPendingReviewFunds();

      if (response.status !== 200) {
        return rejectWithValue({
          status: response.status,
          error: response.data?.error || 'Failed to fetch pending review funds',
        });
      }

      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response?.status || 500,
        error:
          error.message ||
          'Unknown error occurred while fetching pending review funds',
      });
    }
  }
);

export const getRecentReferences = createAsyncThunk(
  'GET_RECENT_REFERENCES',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchRecentReferences();

      if (response.status !== 200) {
        return rejectWithValue({
          status: response.status,
          error: response.data?.error || 'Failed to fetch recent references',
        });
      }

      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response?.status || 500,
        error:
          error.message ||
          'Unknown error occurred while fetching recent references',
      });
    }
  }
);

export const getRecentClosedFunds = createAsyncThunk(
  'GET_RECENT_CLOSED_FUNDS',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchRecentClosedFunds();

      if (response.status !== 200) {
        return rejectWithValue({
          status: response.status,
          error: response.data?.error || 'Failed to fetch recent closed funds',
        });
      }

      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response?.status || 500,
        error:
          error.message ||
          'Unknown error occurred while fetching recent closed funds',
      });
    }
  }
);

const initialState = {
  dueDiligenceFunds: [],
  dueDiligenceColumns: [],
  pendingReviewFunds: [],
  pendingReviewColumns: [],
  recentReferences: [],
  recentReferencesColumns: [],
  recentClosedFunds: [],
  recentClosedColumns: [],
  committedActiveFunds: [],
  committedActiveColumns: [],
};

export const landingPageSlice = createSlice({
  name: 'landingPage',
  initialState,
  reducers: {
    setDueDiligenceFunds: (state, action) => {
      state.dueDiligenceFunds = action.payload;
    },
    setDueDiligenceColumns: (state, action) => {
      state.dueDiligenceColumns = action.payload;
    },
    setCommittedActiveFunds: (state, action) => {
      state.committedActiveFunds = action.payload;
    },
    setCommittedActiveColumns: (state, action) => {
      state.committedActiveColumns = action.payload;
    },
    setPendingReviewFunds: (state, action) => {
      state.pendingReviewFunds = action.payload;
    },
    setPendingReviewColumns: (state, action) => {
      state.pendingReviewColumns = action.payload;
    },
    setRecentReferences: (state, action) => {
      state.recentReferences = action.payload;
    },
    setRecentReferencesColumns: (state, action) => {
      state.recentReferencesColumns = action.payload;
    },
    setRecentClosedFunds: (state, action) => {
      state.recentClosedFunds = action.payload;
    },
    setRecentClosedColumns: (state, action) => {
      state.recentClosedColumns = action.payload;
    },
  },
});

// Export actions
export const {
  setDueDiligenceFunds,
  setDueDiligenceColumns,
  setPendingReviewFunds,
  setPendingReviewColumns,
  setRecentReferences,
  setRecentReferencesColumns,
  setRecentClosedFunds,
  setRecentClosedColumns,
  setCommittedActiveColumns,
  setCommittedActiveFunds,
} = landingPageSlice.actions;

// Export the reducer
export default landingPageSlice.reducer;
