import { useEffect, useState } from 'react';
import { BarChart } from 'components';
import { getJsonValue } from 'services/utils';

const TrackRecord = ({ fundData, fundkey }) => {
  const [fundNames, setFundNames] = useState([]);
  const [irrPercentages, setIrrPercentages] = useState([]);
  const [yAxis, setYAxis] = useState('Net IRR(%)');
  const [itemsToShow, setItemsToShow] = useState(5);

  const formatter = (val) => {
    return `${val}%`;
  };

  const preprocessData = (dataWithPercentage) => {
    return dataWithPercentage.map((item) => {
      return parseFloat(item.replace('%', ''));
    });
  };

  const generateColors = (numColors) => {
    const colors = ['#418ab3', '#a6b727', '#f69200', '#838383', '#fec306'];
    const ret = [];
    for (let i = 0; i < numColors; i++) {
      const color = colors[i % colors.length];
      ret.push(color);
    }
    return ret;
  };

  useEffect(() => {
    const fundDetailsData = fundData?.sections?.find(
      (item) => item?.['fund-benchmarks']
    );
    if (fundDetailsData && fundDetailsData?.['fund-benchmarks']) {
      const teamMembers = getJsonValue(
        fundDetailsData?.['fund-benchmarks'],
        'values'
      );

      const irrPercent = [];
      const names = [];
      teamMembers?.forEach((fund) => {
        const check = !!fund['benchmark_net_irr_in_percent'];
        const nameCheck = !!fund['benchmark_fund_name'];
        if (nameCheck) {
          names.push(fund['benchmark_fund_name']);
          irrPercent.push(check ? fund['benchmark_net_irr_in_percent'] : '0');
        }
      });
      setIrrPercentages(preprocessData(irrPercent));
      setFundNames(names);
    }
  }, [fundkey, fundData, itemsToShow]);
  return (
    <div className="">
      <div className="fix-height">
        <BarChart
          categories={fundNames}
          values={irrPercentages}
          horizontal={false}
          formatter={formatter}
          yAxis={yAxis}
          generateColors={generateColors}
        />
      </div>
    </div>
  );
};

export default TrackRecord;
