import React, { useEffect } from 'react';
import axios from 'axios';
import { getToken, setToken } from 'services/utils';
import { baseAuth } from 'services/constants';
import { logout } from 'modules/login/slice/loginSlice';
import { Routing, router } from 'router';

const apiHandler = (App) => {
  const Interceptor = (props) => {
    useEffect(() => {
      axios.interceptors.response.use(
        (response) => {
          return response;
          //   if (response?.data) {
          //     return { data: response?.data, status: response.status };
          //   } else {
          //     return response;
          //   }
        },
        (error) => {
          switch (error.response.status) {
            case 500:
              // toast.show({
              //   position: 'bottom',
              //   type: 'error',
              //   text1: 'Internal Server Error ! Please Try after sometimes',
              //   visibilityTime: 1500,
              // });
              break;
            case 400:
              // toast.show({
              //   position: 'bottom',
              //   type: 'error',
              //   text1: 'Something Went Wrong',
              //   visibilityTime: 1500,
              // });
              break;
            case 403:
              setToken('EXPIRED');
              console.log(
                'User credentials have expired. Please log in again.'
              );
              //logout();
              router.navigate('/login');

              //navigate('/login');
              // toast.show({
              //   position: 'bottom',
              //   type: 'error',
              //   text1: 'User already login in another device',
              //   visibilityTime: 1500,
              // });
              break;
            case 404:
              // toast.show({
              //   position: 'bottom',
              //   type: 'error',
              //   text1: 'Something Went Wrong',
              //   visibilityTime: 1500,
              // });
              break;
            default:
              break;
          }

          // Do something with response error
          // toast.show({
          //   position: 'bottom',
          //   type: 'error',
          //   text1: error.message,
          //   visibilityTime: 1500
          // })

          return Promise.reject(error);
        }
      );
    });

    return <App {...props} />;
  };
  return Interceptor;
};

export default apiHandler;
