import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

const CustomButton = ({
  id = 'priButton',
  name = 'mainName',
  onClick,
  isDisable,
  className,
  textClass,
  icon = '',
  isReverseIcon,
}) => {
  const btnClass = classNames(className, {
    buttonStyle: true,
    'btn-disabled': isDisable,
  });

  const txtClass = classNames(textClass, {
    txtStyle: true,
  });

  return (
    <button
      key={id}
      disabled={isDisable}
      className={btnClass}
      onClick={() => onClick()}
    >
      {isReverseIcon ? null : <ReactSVG src={icon} />}
      <div className={txtClass}>{name}</div>
      {isReverseIcon ? <ReactSVG src={icon} /> : null}
    </button>
  );
};

export default CustomButton;
