import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { getToken } from 'services/utils';
import { selectUserLoggedIn } from 'modules/login/slice/loginSlice';

const PrivateRoute = ({ children }) => {
  const isLoggedIn = useSelector(selectUserLoggedIn);
  const jwt_token = getToken();
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    const params = {
      pathname,
      id: id,
    };
    localStorage.setItem('urlParams', JSON.stringify(params));
  }, []);

  if (!(isLoggedIn && jwt_token)) {
    // user is not authenticated
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};

export default PrivateRoute;
