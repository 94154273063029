import React from 'react';
import { LoaderLogo } from 'assets';

const Loader = () => {
  return (
    <div className="lod-overlay">
      <div className="load-container">
        <div className="loader"></div>
        <img src={LoaderLogo} className="lod-img" alt="" />
      </div>
    </div>
  );
};

export default Loader;
