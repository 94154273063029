import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fundData: null,
  fundCompareTable: null,
  displayNames: false,
  fundFieldCol: [],
  isBlackBookCollapse: false,
  fundBenchmark: {},
  selecteFundCompare: [],
};

const fundDataSlice = createSlice({
  name: 'fundData',
  initialState,
  reducers: {
    setFundData: (state, action) => {
      state.fundData = action.payload;
    },
    setFundBenchmark: (state, action) => {
      state.fundBenchmark = action.payload;
    },
    setFundCompareTable: (state, action) => {
      state.fundCompareTable = action.payload;
    },
    setDisplayNames: (state, action) => {
      state.displayNames = action.payload;
    },
    setFundFieldCol: (state, action) => {
      state.fundFieldCol = action.payload;
    },
    setIsBlackBookCollapse: (state, action) => {
      state.isBlackBookCollapse = action.payload;
    },
    setSelectedFundCompares: (state, action) => {
      state.selecteFundCompare = action.payload;
    },
    setDisplayConfigOrder: (state, action) => {
      state.displayUserConfig = action.payload;
    },
  },
});

export const {
  setFundData,
  setFundCompareTable,
  setDisplayNames,
  setFundFieldCol,
  setIsBlackBookCollapse,
  setSelectedFundCompares,
  setFundBenchmark,
  setDisplayConfigOrder,
} = fundDataSlice.actions;

export default fundDataSlice.reducer;
