import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { languages } from 'services/constants';
import { setToken } from 'services/utils';
import { getPortfolios } from './pacingPlanActions';

export const grabPortfolios = createAsyncThunk(
  'GET_PORTFOLIOS',
  async (requestObject) => {
    try {
      const data = await getPortfolios(requestObject);
      return { data: data.data, status: data.status };
    } catch (error) {
      console.log('error = ', error);
      return error.response;
    }
  }
);

const initialState = {
  portfolioData: [],
};

const portfolioSlice = createSlice({
  name: 'fundData',
  initialState,
  reducers: {
    setPortfolioData: (state, action) => {
      console.log('INSIDE SET PORTFOLIO DATA => ', action);
      state.portfolioData = action.payload;
    },
  },
});

export const { setPortfolioData } = portfolioSlice.actions;

export default portfolioSlice.reducer;
