import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notification: false,
  fundId: null,
  groupID: null,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setFundId: (state, action) => {
      state.fundId = action.payload;
    },
    setGroupID: (state, action) => {
      state.groupID = action.payload;
    },
  },
});

export const { setNotification, setFundId, setGroupID } = uiSlice.actions;

export default uiSlice.reducer;
