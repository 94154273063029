import classNames from 'classnames';
import Select from 'react-select';

const SelectBox = ({
  id,
  onChange,
  isDisable,
  className,
  value,
  placeholder,
  label,
  infoTxt,
  options,
  position,
  ...rest
}) => {
  const lablStyle = classNames(className, {
    'lblTxt filLbl': true,
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      minHeight: 30,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return (
    <div>
      {label ? <div className={lablStyle}>{label} </div> : null}

      <Select
        value={value}
        options={options}
        isSearchable={true}
        styles={customStyles}
        menuPosition={position === 'fixed' ? 'absolute' : 'fixed'}
        placeholder={placeholder}
        classNamePrefix="react-select-custom"
        className="react-select-container-custom"
        onChange={(value, index) => onChange(index, value)}
      />
    </div>
  );
};

SelectBox.defaultProps = {
  id: 'priButton',
  name: 'mainName',
};
export default SelectBox;
