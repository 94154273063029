import React from 'react';
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from 'react-icons/md';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  let pagination = [],
    i = 1;

  while (i <= totalPages) {
    if (
      i <= 3 || //the first three pages
      i >= totalPages - 2 || //the last three pages
      (i >= currentPage - 1 && i <= currentPage + 1)
    ) {
      //the currentPage, the page before and after
      pagination.push(i);
      i++;
    } else {
      //any other page should be represented by ...
      pagination.push('...');
      //jump to the next page to be linked in the navigation
      i = i < currentPage ? currentPage - 1 : totalPages - 2;
    }
  }
  if (!totalPages) {
    return null;
  }
  return (
    <div className="pager-container d-flex justify-content-between">
      <div>
        <MdOutlineKeyboardArrowLeft
          className={currentPage === 1 ? 'pg-icon-dis' : 'pg-icon'}
        />
        <button
          className={currentPage === 1 ? 'prv-btn-dis' : 'prv-btn'}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
      </div>

      <div>
        {pagination?.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            disabled={pageNumber === '...'}
            className={
              pageNumber === currentPage
                ? 'active btn mx-1 pg-num'
                : 'btn mx-1 pg-num'
            }
          >
            {pageNumber}
          </button>
        ))}
      </div>

      <div>
        <button
          className={currentPage === totalPages ? 'nxt-btn-dis' : 'nxt-btn'}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
        <MdOutlineKeyboardArrowRight
          className={currentPage === totalPages ? 'pg-icon-dis' : 'pg-icon'}
        />
      </div>
    </div>
  );
};

export default Pagination;
