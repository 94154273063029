import { memo, React, useState } from 'react';
import { forEach } from 'lodash';
import { any, array, bool, func, string } from 'prop-types';
import { useTable, useGlobalFilter } from 'react-table';
import { FaSort } from 'react-icons/fa';
import Pagination from 'components/Pagination';
import './styles.css';
import { IoChatboxEllipses } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Tooltip } from 'ui-controls';
import { MdEdit } from 'react-icons/md';
import ReactMarkdown from 'react-markdown';
import { showToast } from 'services/utils/toastUtility';

// import { no_data } from 'assets';
const loadingData = [{}, {}, {}, {}, {}, {}, {}, {}];
const Table = memo(
  ({
    data = [],
    columns = [],
    fileName = 'export',
    allowAdd = false,
    addButtonName = 'Add',
    onAdd = () => {},
    onRowClick = () => {},
    className,
    allowExport = false,
    isLoading = false,
    totalPages,
    buttons = null,
    handleAIDrawer = () => {},
    currentPage,
    onTableSort,
    scrollStyle,
    ContainerClass,
    handlePageChange,
    customPagination = true,
    customSorting = true,
    setRowTableData = () => {},
    sectionName = '',
    openDialog,
    setOpenDialog,
  }) => {
    const [defaultSort, setDeafultSort] = useState('ASC');
    const [activehead, setActiveHead] = useState('');
    const {
      rows,
      prepareRow,
      headerGroups,
      getTableProps,
      visibleColumns,
      getTableBodyProps,
    } = useTable(
      {
        data,
        columns,
      },
      useGlobalFilter
      // useSortBy
    );
    const { displayNames } = useSelector(({ fundData }) => fundData);
    let headers = [];
    if (allowExport) {
      forEach(columns, ({ Header, accessor }) => {
        if (Header !== 'Action') {
          headers = [...headers, { label: Header, key: accessor }];
        }
      });
    }

    const onSorting = (value, type) => {
      setDeafultSort(type);
      onTableSort(value, type);
      setActiveHead(value);
    };

    // const onChangeFee = (e) => {
    //   setOpenDialog({
    //     ...openDialog,
    //     value: e.target.value,
    //   });
    // };
    return (
      <div className={ContainerClass}>
        <div className="dashboard-flexBox">
          <div className={scrollStyle}>
            <table width="100%" {...getTableProps()} className="customTable">
              <thead className={className}>
                {headerGroups.map((headerGroup, headerGroupIndex) => (
                  <tr
                    key={headerGroupIndex}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <th
                        key={index}
                        {...column.getHeaderProps({
                          style: {
                            minWidth: column.minWidth,
                            width: column.width,
                          },
                        })}
                      >
                        {column.render('Header')}

                        {customSorting ? (
                          <>
                            {activehead === column.filterKey &&
                            defaultSort === 'ASC' ? (
                              <span
                                className="table-sort-icon"
                                onClick={() =>
                                  onSorting(column.filterKey, 'DESC')
                                }
                              >
                                <FaSort className="sortIC" />
                              </span>
                            ) : (
                              <span
                                className="table-sort-icon"
                                onClick={() =>
                                  onSorting(column.filterKey, 'ASC')
                                }
                              >
                                <FaSort className="sortIC" />
                              </span>
                            )}
                          </>
                        ) : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {isLoading
                  ? loadingData?.map((row, i) => {
                      return (
                        <tr key={i + 'load-row'} className="loader-row">
                          {columns.map((column, index) => {
                            return (
                              <td
                                key={index + 'load-col'}
                                role="cell"
                                // style={{
                                //   width: column.width || 150,
                                //   minWidth: column.minWidth || 150,
                                // }}
                              >
                                <div className="flex ph-item">
                                  <div className="ph-row">
                                    <div className="ph-col-1"></div>
                                  </div>
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  : rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          onClick={(e) =>
                            row.original?.url !== ''
                              ? onRowClick(
                                  row.original,
                                  e.target.closest('button') ? true : false
                                )
                              : showToast(`${row.original?.message}`, 'error')
                          }
                        >
                          {row.cells.map((cell) => {
                            const columnId =
                              cell?.render('Cell')?.props?.column?.id;
                            const spanStyle = {
                              display: 'block',
                              width: '100%',
                              height: '100%',
                              pointerEvents: 'auto',
                              minWidth: '20px',
                              minHeight: '30px',
                              lineHeight: '30px',
                            };
                            return (
                              <>
                                {columnId === 'fee_description' &&
                                sectionName == 'Fees' ? (
                                  <td {...cell.getCellProps()} width="50%">
                                    {/* {cell.render('Cell')} */}
                                    <span
                                      style={spanStyle}
                                      className="cell"
                                      onClick={() => {
                                        const cellProps =
                                          cell.render('Cell')?.props;
                                        const columnId = cellProps?.column?.id;
                                        if (
                                          columnId !== 'Document' &&
                                          columnId !== 'sourceDoc' &&
                                          columnId !== 'sourcePage' &&
                                          window?.location?.pathname !== '/'
                                        ) {
                                          const isPeriodsOrReturn =
                                            columnId === 'Periods' ||
                                            columnId === 'Return' ||
                                            columnId === 'action';
                                          const fieldName = isPeriodsOrReturn
                                            ? cellProps?.row?.cells?.[0]?.value
                                                ?.replace(/ /g, '_')
                                                ?.toLowerCase()
                                            : columnId;
                                          const content = isPeriodsOrReturn
                                            ? cellProps?.row?.cells?.[1]?.value
                                            : cellProps?.cell?.value;
                                          const sourceDoc =
                                            cell?.row?.original?.sourceDoc !==
                                              null &&
                                            cell?.row?.original?.sourceDoc !==
                                              'null'
                                              ? `s3://{process.env.AWS_BUCKET_FUND_DOCS}/${cell?.row?.original?.sourceDoc}`
                                              : '';

                                          const sourcePage =
                                            cell?.row?.original?.sourcePage !==
                                              null &&
                                            cell?.row?.original?.sourcePage !==
                                              'null'
                                              ? cell?.row?.original?.sourcePage
                                              : '';
                                          handleAIDrawer({
                                            isDrawerOpen: true,
                                            fieldName,
                                            content: content ? content : '',
                                            sourceDoc,
                                            rowData: cellProps?.row?.original,
                                            rowId: isPeriodsOrReturn ? null : i,
                                            sourcePage,
                                          });
                                          if (
                                            !isPeriodsOrReturn &&
                                            sectionName !== 'FundDocuments' &&
                                            typeof setRowTableData == 'function'
                                          ) {
                                            setRowTableData({
                                              row: cellProps?.row?.original,
                                              column: cellProps?.column,
                                              columns: cellProps?.columns,
                                              rowId: i,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      {/* {cell
                                        ?.render('Cell')
                                        ?.props?.cell?.value?.props?.children?.slice(
                                          0,
                                          180
                                        )} */}
                                      <ReactMarkdown>
                                        {cell
                                          ?.render('Cell')
                                          ?.props?.cell?.value?.props?.children?.slice(
                                            0,
                                            180
                                          )}
                                      </ReactMarkdown>
                                    </span>
                                    {cell?.render('Cell')?.props?.cell?.value
                                      ?.props?.children?.length > 180 &&
                                      !openDialog?.dialog && <>...</>}
                                    {cell?.render('Cell')?.props?.cell?.value
                                      ?.props?.children?.length > 30 && (
                                      <div
                                        className="btn"
                                        style={{
                                          color: '#3498db',
                                          fontSize: '12px',
                                        }}
                                        onClick={() =>
                                          setOpenDialog({
                                            dialog: !openDialog?.dialog,
                                            content: cell.render('Cell'),
                                            accessor: columnId,
                                            index: i,
                                            value:
                                              cell.render('Cell')?.props?.cell
                                                ?.value?.props?.children,
                                          })
                                        }
                                      >
                                        <Tooltip label="More">
                                          More & Edit
                                        </Tooltip>
                                      </div>
                                    )}
                                  </td>
                                ) : columnId === 'description' &&
                                  sectionName == 'PortfolioTeam' ? (
                                  <td {...cell.getCellProps()} width="25%">
                                    {/* {cell.render('Cell')} */}
                                    <span
                                      style={spanStyle}
                                      className="cell"
                                      onClick={() => {
                                        const cellProps =
                                          cell.render('Cell')?.props;
                                        const columnId = cellProps?.column?.id;
                                        if (
                                          columnId !== 'Document' &&
                                          columnId !== 'sourceDoc' &&
                                          columnId !== 'sourcePage' &&
                                          window?.location?.pathname !== '/'
                                        ) {
                                          const isPeriodsOrReturn =
                                            columnId === 'Periods' ||
                                            columnId === 'Return' ||
                                            columnId === 'action';
                                          const fieldName = isPeriodsOrReturn
                                            ? cellProps?.row?.cells?.[0]?.value
                                                ?.replace(/ /g, '_')
                                                ?.toLowerCase()
                                            : columnId;
                                          const content = isPeriodsOrReturn
                                            ? cellProps?.row?.cells?.[1]?.value
                                            : cellProps?.cell?.value;
                                          const sourceDoc =
                                            cell?.row?.original?.sourceDoc !==
                                              null &&
                                            cell?.row?.original?.sourceDoc !==
                                              'null'
                                              ? `s3://{process.env.AWS_BUCKET_FUND_DOCS}/${cell?.row?.original?.sourceDoc}`
                                              : '';

                                          const sourcePage =
                                            cell?.row?.original?.sourcePage !==
                                              null &&
                                            cell?.row?.original?.sourcePage !==
                                              'null'
                                              ? cell?.row?.original?.sourcePage
                                              : '';
                                          handleAIDrawer({
                                            isDrawerOpen: true,
                                            fieldName,
                                            content: content ? content : '',
                                            sourceDoc,
                                            rowData: cellProps?.row?.original,
                                            rowId: isPeriodsOrReturn ? null : i,
                                            sourcePage,
                                          });
                                          if (
                                            !isPeriodsOrReturn &&
                                            sectionName !== 'FundDocuments' &&
                                            typeof setRowTableData == 'function'
                                          ) {
                                            setRowTableData({
                                              row: cellProps?.row?.original,
                                              column: cellProps?.column,
                                              columns: cellProps?.columns,
                                              rowId: i,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <ReactMarkdown>
                                        {cell
                                          ?.render('Cell')
                                          ?.props?.cell?.value?.props?.children?.slice(
                                            0,
                                            80
                                          )}
                                      </ReactMarkdown>
                                    </span>
                                    {cell?.render('Cell')?.props?.cell?.value
                                      ?.props?.children?.length > 80 &&
                                      !openDialog?.dialog && <>...</>}
                                    {cell?.render('Cell')?.props?.cell?.value
                                      ?.props?.children?.length > 30 && (
                                      <div
                                        className="btn"
                                        style={{
                                          color: '#3498db',
                                          fontSize: '12px',
                                        }}
                                        onClick={() =>
                                          setOpenDialog({
                                            dialog: !openDialog?.dialog,
                                            content: cell.render('Cell'),
                                            accessor: columnId,
                                            index: i,
                                            value:
                                              cell.render('Cell')?.props?.cell
                                                ?.value?.props?.children,
                                          })
                                        }
                                      >
                                        <Tooltip label="More">
                                          More & Edit
                                        </Tooltip>
                                      </div>
                                    )}
                                  </td>
                                ) : (
                                  <td {...cell.getCellProps()}>
                                    <span
                                      style={spanStyle}
                                      className="cell"
                                      onClick={() => {
                                        const cellProps =
                                          cell.render('Cell')?.props;
                                        const columnId = cellProps?.column?.id;
                                        if (
                                          columnId !== 'Document' &&
                                          columnId !== 'sourceDoc' &&
                                          columnId !== 'sourcePage' &&
                                          window?.location?.pathname !== '/'
                                        ) {
                                          const isPeriodsOrReturn =
                                            columnId === 'Periods' ||
                                            columnId === 'Return' ||
                                            columnId === 'action';
                                          const fieldName = isPeriodsOrReturn
                                            ? cellProps?.row?.cells?.[0]?.value
                                                ?.replace(/ /g, '_')
                                                ?.toLowerCase()
                                            : columnId;
                                          const content = isPeriodsOrReturn
                                            ? cellProps?.row?.cells?.[1]?.value
                                            : cellProps?.cell?.value;
                                          const sourceDoc =
                                            cell?.row?.original?.sourceDoc !==
                                              null &&
                                            cell?.row?.original?.sourceDoc !==
                                              'null'
                                              ? `s3://{process.env.AWS_BUCKET_FUND_DOCS}/${cell?.row?.original?.sourceDoc}`
                                              : '';

                                          const sourcePage =
                                            cell?.row?.original?.sourcePage !==
                                              null &&
                                            cell?.row?.original?.sourcePage !==
                                              'null'
                                              ? cell?.row?.original?.sourcePage
                                              : '';
                                          handleAIDrawer({
                                            isDrawerOpen: true,
                                            fieldName,
                                            content: content ? content : '',
                                            sourceDoc,
                                            rowData: cellProps?.row?.original,
                                            rowId: isPeriodsOrReturn ? null : i,
                                            sourcePage,
                                          });
                                          if (
                                            !isPeriodsOrReturn &&
                                            sectionName !== 'FundDocuments' &&
                                            typeof setRowTableData == 'function'
                                          ) {
                                            setRowTableData({
                                              row: cellProps?.row?.original,
                                              column: cellProps?.column,
                                              columns: cellProps?.columns,
                                              rowId: i,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      {sectionName === 'BlackBookFundCompare' &&
                                      window?.location?.pathname == '/fund' ? (
                                        <Tooltip
                                          id={`tooltip-${row.id}-${columnId}`}
                                          label={cell.render('Cell')}
                                        >
                                          {cell.render('Cell')}
                                        </Tooltip>
                                      ) : (
                                        cell.render('Cell')
                                      )}
                                    </span>
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                      );
                    })}
              </tbody>
            </table>
            <table className="customTable" width="100%">
              <tr className="paginationRow">
                {customPagination && rows?.length ? (
                  <div className="paginationWrp">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                ) : (
                  false
                )}
              </tr>
            </table>
            <table width="100%">
              <tbody>
                {!rows?.length && !isLoading ? (
                  <tr
                    className="no-hover no-data-text"
                    colSpan={visibleColumns.length}
                  >
                    <td className="no-data-text">
                      <div className="no-data">
                        No data found.
                        {(sectionName === 'Fees' ||
                          sectionName === 'PortfolioTeam' ||
                          sectionName === 'PortfolioCompanies' ||
                          sectionName === 'FundBenchmarks') && (
                          <button
                            style={{ float: 'right' }}
                            onClick={() =>
                              handleAIDrawer({
                                isDrawerOpen: true,
                                fieldName: '',
                                sourceDoc: '',
                                content: '',
                                isDropdown: false,
                                sectionId: sectionName,
                                rowData: displayNames,
                              })
                            }
                            className="btn btn-primary"
                          >
                            <IoChatboxEllipses />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
);

Table.propTypes = {
  columns: array.isRequired,
  data: array.isRequired,
  allowAdd: bool,
  isLoading: bool,
  addButtonName: any,
  onAdd: func,
  allowExport: bool,
  fileName: string,
  filter: any,
  buttons: any,
  onRowClick: func,
  setRowTableData: func,
};
export default Table;
