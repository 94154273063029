import classNames from 'classnames';

const PrimaryButton = ({
  id = 'priButton',
  name = 'mainName',
  onClick,
  isDisable,
  className,
  textClass,
}) => {
  const btnClass = classNames(className, {
    buttonStyle: true,
    'btn-disabled': isDisable,
  });

  const txtClass = classNames(textClass, {
    txtStyle: true,
  });

  return (
    <button
      key={id}
      disabled={isDisable}
      className={btnClass}
      onClick={() => onClick()}
    >
      <div className={txtClass}>{name}</div>
    </button>
  );
};

export default PrimaryButton;
