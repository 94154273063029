import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import { LuImagePlus } from 'react-icons/lu';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

const Uploader = ({
  id,
  onChange,
  src = '',
  value = '',
  label,
  name,
  description,
  maxSize = '',
  containerStyle = 'w-24 h-24',
  counter,
  setCounter,
  setAllUploadsCompleted,
  setUploadError,
  configMapping,
  findFlag,
  setIsRemoveDoc,
  handleFileAddition,
  documentOrData = true,
  ...rest
}) => {
  const fileTypesAndExtensions = {
    'application/pdf': '.pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      '.docx',
    'application/msword': '.doc',
    'application/vnd.ms-powerpoint': '.ppt',
    'application/vnd.ms-excel.sheet.macroEnabled.12': '.xlsm',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      '.xlsx',
    'application/vnd.ms-excel': '.xls',
    'text/plain': '.txt',
    'video/mp4': '.mp4',
    'audio/mp4': '.m4a',
    'audio/mpeg': '.mp3',
  };

  const buttonStyle = {
    display: 'block',
    margin: '10px auto',
    padding: '10px 20px',
    backgroundColor: 'rgb(120 120 120)',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '110px',
    textAlign: 'center',
  };

  const { userData } = useSelector(({ login }) => login);
  const [isUploading, setIsUploading] = useState(false);
  const onDrop = (acceptedFiles, event) => {
    console.log('document ondrop');
    startUploading();
    let files = null;
    if (event?.target?.files) {
      files = Array.from(event?.target?.files);
    } else {
      files = acceptedFiles;
    }
    if (files?.length) {
      convertBlob(files); // Process the files for upload
      event.target.value = '';
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    noClick: true, // Prevents automatic file input click
  });

  const handleFileClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleFolderClick = () => {
    document.getElementById('folderInput').click();
  };

  useEffect(() => {
    let interval;
    if (isUploading) {
      interval = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter < 100) {
            return prevCounter + 1;
          } else {
            clearInterval(interval);
            setIsUploading(false);
            setIsRemoveDoc(false);
            return prevCounter;
          }
        });
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isUploading]);

  useEffect(() => {
    if (setAllUploadsCompleted) {
      if (isUploading === false) {
        setUploadError();
      }
      setAllUploadsCompleted(!isUploading);
    }
  }, [isUploading, setAllUploadsCompleted]);

  const startUploading = () => {
    setCounter(0);
    setIsUploading(true);
    setIsRemoveDoc(true);
  };

  const categorizeDocument = (docString) => {
    let ret = 'Uncategorized';
    if (Object.keys(configMapping).length > 0) {
      for (const classification of configMapping.FundDocClassification) {
        let [key, values] = Object.entries(classification).find(([k, v]) =>
          Array.isArray(v)
        );
        if (classification.llmprocessing == documentOrData) {
          for (const value of values) {
            const regex = new RegExp(`${value}`, 'i');
            if (regex.test(docString)) {
              ret = key;
              let llm_flag = findFlag({ newType: ret });
              return ret;
            }
          }
        }
      }
    }
    return ret;
  };

  function cleanPath(path) {
    if (!!path) {
      let noFirstSlash = path.startsWith('/') ? path.slice(1) : path;
      let noFileName = noFirstSlash.substring(0, noFirstSlash.lastIndexOf('/'));
      return noFileName;
    } else return '';
  }

  const convertBlob = (data) => {
    let newFiles = [];
    data.forEach((element) => {
      const fileName = element.name;
      if (fileTypesAndExtensions[element.type]) {
        let reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onloadend = function () {
          let obj = {};
          obj.document_name = element.name;
          obj.document_size = element.size;
          obj.document_path = reader.result;
          obj.document_type = categorizeDocument(fileName);
          let flag = findFlag({ newType: obj.document_type });
          obj.added_by_user = userData.user_id;
          obj.llmprocessing = flag;
          obj.file_path = !!element.path
            ? cleanPath(element.path)
            : element.webkitRelativePath
              ? cleanPath(element.webkitRelativePath)
              : '';
          newFiles.push(obj);
          // if (newFiles.length === data.length) {
          // Call the new function to add files based on their count
          // handleFileAddition(newFiles);
          // }
          if (
            newFiles.length ===
            data.filter((file) => fileTypesAndExtensions[file.type]).length
          ) {
            handleFileAddition(newFiles);
            console.log('handleFileAdditoin Called! = ', newFiles);
          }
        };
      }
    });
  };

  return (
    <Fragment key={id}>
      <div
        {...getRootProps({ className: 'dropzone' })}
        style={{
          alignContent: 'center',
          border: '2px dashed #6f6f6f',
          height: '16rem',
          margin: '11px',
          width: '41rem',
          borderRadius: '6px',
          textAlign: 'center',
        }}
      >
        <input {...getInputProps()} />
        <input
          type="file"
          id="fileInput"
          multiple
          style={{ display: 'none' }}
          onChange={(e) => onDrop(e.target.files, e)}
        />
        <input
          type="file"
          id="folderInput"
          style={{ display: 'none' }}
          webkitdirectory=""
          mozdirectory=""
          onChange={(e) => onDrop(e.target.files, e)}
        />
        <button onClick={handleFileClick} style={buttonStyle}>
          Add Files
        </button>
        <button onClick={handleFolderClick} style={buttonStyle}>
          Add Folder
        </button>
        <p>or drag and drop files into this box</p>
      </div>
    </Fragment>
  );
};

export default Uploader;
