import React, { useEffect, useState } from 'react';
import Table from 'components/Grid';
import ReactMarkdown from 'react-markdown';

const PdfFees = ({
  generateTableFee,
  size,
  investment_pacing,
  fund_raise_period,
  investment_period,
  investment_term,
  carried_interest,
  net_irr,
  expected_net_returns_in_moic,
  net_roi,
}) => {
  const renderMarkdown = (text) => (
    <ReactMarkdown
      components={{
        p: ({ node, ...props }) => <span {...props} />,
      }}
    >
      {text}
    </ReactMarkdown>
  );
  console.log('investment_pacing', renderMarkdown(investment_period));

  generateTableFee?.unshift({
    key: carried_interest && 'Carried Interest',
    value: carried_interest && carried_interest,
  });

  console.log('fundFeeData', generateTableFee);

  const fundLevelData = [
    { name: 'Strategy Size', value: renderMarkdown(size) || 'N/A' },
    {
      name: 'Investment Pacing',
      value: renderMarkdown(investment_pacing) || 'N/A',
    },
    {
      name: 'Fundraise Period',
      value: renderMarkdown(fund_raise_period) || 'N/A',
    },
    {
      name: 'Investment Period',
      value: renderMarkdown(investment_period) || 'N/A',
    },
    {
      name: 'Investment Term',
      value: renderMarkdown(investment_term) || 'N/A',
    },
  ];

  const fundLevelColumns = [
    {
      Header: 'Fund Level',
      accessor: 'name',
    },
    {
      Header: '',
      accessor: 'value',
    },
  ];

  const fundFeesColumns = [
    {
      Header: 'Fees and Expenses',
      accessor: 'key',
    },
    {
      Header: '',
      accessor: 'value',
    },
  ];

  const positionLevelColumns = [
    {
      Header: 'Position level',
      accessor: 'name',
    },
    {
      Header: '',
      accessor: 'value',
    },
  ];

  const positionLevelData = [
    { name: 'Gross IRR:', value: '' },
    {
      name: 'Gross multiple on invested capital:',
      value: '',
    },
    {
      name: 'Income Componen:',
      value: '',
    },
    {
      name: 'Reinvestment:',
      value: '',
    },
    {
      name: 'Hold Period:',
      value: '',
    },
  ];

  const returnExpColumns = [
    {
      Header: 'Return Expectation',
      accessor: 'name',
    },
    {
      Header: '',
      accessor: 'value',
    },
  ];

  const returnExpData = [
    { name: 'Net IRR:', value: net_irr },
    {
      name: 'Net MOIC:',
      value: expected_net_returns_in_moic,
    },
    {
      name: 'Net ROI:',
      value: net_roi,
    },
  ];

  return (
    <div>
      <div className="dashboard-flexBox-pdf ">
        <Table
          allowAdd={false}
          allowExport={false}
          data={fundLevelData}
          isLoading={false}
          columns={fundLevelColumns}
          customSorting={false}
          customPagination={false}
          // scrollStyle="scrollable-fund scroll fix-height"
          ContainerClass="table-container-fund"
        />
      </div>
      <div className="dashboard-flexBox-pdf ">
        <Table
          allowAdd={false}
          allowExport={false}
          data={generateTableFee !== null ? generateTableFee : []}
          isLoading={false}
          columns={fundFeesColumns}
          customSorting={false}
          customPagination={false}
          // scrollStyle="scrollable-fund scroll fix-height"
          ContainerClass="table-container-fund"
        />
      </div>
      {/*<div className="dashboard-flexBox-pdf ">
        <Table
          allowAdd={false}
          allowExport={false}
          data={positionLevelData}
          isLoading={false}
          columns={positionLevelColumns}
          customSorting={false}
          customPagination={false}
          // scrollStyle="scrollable-fund scroll fix-height"
          ContainerClass="table-container-fund"
        />
      </div>*/}
      <div className="dashboard-flexBox-pdf ">
        <Table
          allowAdd={false}
          allowExport={false}
          data={returnExpData}
          isLoading={false}
          columns={returnExpColumns}
          customSorting={false}
          customPagination={false}
          // scrollStyle="scrollable-fund scroll fix-height"
          ContainerClass="table-container-fund"
        />
      </div>
    </div>
  );
};

export default PdfFees;
