import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const PasswordBox = ({
  id,
  onChange,
  isDisable,
  className,
  value,
  placeholder,
  label,
  onKeyDown,
  autocomplete = true,
  ...rest
}) => {
  const { isLanguage } = useSelector((state) => state.login);
  const [showPassword, setShowPassword] = useState(false);
  const newpwd = autocomplete ? 'on' : 'new-password';
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const inputClass = classNames(className, {
    inputStyle: true,
    'input-disabled': isDisable,
    'Txt-REV': isLanguage === 'ARB' ? true : false,
  });

  const lablStyle = classNames(className, {
    lblTxt: true,
    'lblTxt-REV': isLanguage === 'ARB' ? true : false,
  });

  return (
    <div>
      {label ? <div className={lablStyle}>{label} </div> : null}
      <div className="flex pwd-box-wrapper">
        <input
          key={id}
          type={showPassword ? 'text' : 'password'}
          disabled={isDisable}
          onChange={(e) => onChange(e)}
          className={inputClass + ' spaceRight'}
          value={value}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          autoComplete={newpwd}
          {...rest}
        />
        <div className="pwdIcon" onClick={() => toggleShowPassword()}>
          {showPassword ? <FaEye /> : <FaEyeSlash />}
        </div>
      </div>
    </div>
  );
};

export default PasswordBox;
