import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import '../../PacingPlan/style/index.css';

function PdfPortfolios({ portfolioData }) {
  const [statePortfolio, setStatePortfolio] = useState(
    useSelector((state) => state?.portfolio?.portfolioData)
  );
  const [allPercentages, setAllPercentages] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [allNames, setAllNames] = useState([]);
  useEffect(() => {
    parsePacingPlansAndColumns(statePortfolio);
  }, []);

  const renderMarkdown = (text) => (
    <ReactMarkdown
      components={{
        p: ({ node, ...props }) => <span {...props} />,
      }}
    >
      {text}
    </ReactMarkdown>
  );

  const parsePacingPlansAndColumns = (data) => {
    const portfolios = data?.payload?.data?.portfolios;

    // Initialize an empty object to store asset classes and their corresponding targets
    const portfolio_percentages = [];
    const assetClassMapping = [];
    const names = [];
    const initCol = {
      Header: 'Asset Class',
      accessor: 'asset_class',
    };
    const buildColumns = [];

    // Loop through each portfolio
    portfolios?.forEach((portfolioData, portfolioIndex) => {
      assetClassMapping.push({});
      portfolio_percentages.push([]);
      buildColumns.push([initCol]);

      const percentages = portfolioData.portfolio.current_allocation.portfolios;
      const pacingPlans = portfolioData.pacing_plans;

      names.push(portfolioData.portfolio.current_allocation.name);

      // Iterate through each pacing plan within a portfolio
      pacingPlans?.forEach((pacingPlan, index) => {
        const fiscalYearIndex = `target${index + 1}`; // Create dynamic keys like target1, target2, etc.

        // Add column header dynamically based on fiscal year and pacing target
        const column = {
          Header: `${pacingPlan.fiscal_year} (${pacingPlan.pacing_target_priv_alloc_pct}% target)`,
          accessor: fiscalYearIndex,
        };

        // Only add the column if it does not already exist (prevent duplicates)
        if (
          !buildColumns[portfolioIndex].some(
            (col) => col.accessor === fiscalYearIndex
          )
        ) {
          buildColumns[portfolioIndex].push(column);
        }

        // Loop through the details of each pacing plan
        pacingPlan?.details?.details?.forEach((detail) => {
          const assetClass = detail.asset_class;
          const amount = detail.amount;

          // Initialize the asset class if it doesn't exist in the mapping
          if (!assetClassMapping[portfolioIndex][assetClass]) {
            assetClassMapping[portfolioIndex][assetClass] = {
              asset_class: assetClass,
            };
          }

          // Assign the amount to the appropriate target
          assetClassMapping[portfolioIndex][assetClass][fiscalYearIndex] =
            amount;
        });
      });
      percentages?.forEach((portfolioPercents, index) => {
        if (portfolioPercents.amount_type === 'pct') {
          portfolio_percentages[portfolioIndex].push({
            percentage: portfolioPercents['amount'],
            asset_class: portfolioPercents['asset_class'],
          });
        }
      });
    });

    // Convert the assetClassMapping object into an 2D array, each array composed of rows for a portfolio
    const buildRows = assetClassMapping.map((portfolio) =>
      Object.values(portfolio)
    );

    setAllRows(buildRows);
    setAllColumns(buildColumns);
    setAllPercentages(portfolio_percentages);

    setAllNames(names);

    return {
      buildRows,
      buildColumns,
    };
  };

  const buildPacingRow = (index) => {
    const columns = allColumns[index];
    const rows = allRows[index];
    const percentages = allPercentages[index];
    const name = allNames[index];
    return (
      <div className="pacingRowBlackbook">
        {/* Build Pacing Table */}
        {buildPacingTable({ columns: columns, rows: rows, name: name })}

        {/* Build Pacing Chart */}
        {buildPacingChart({ data: percentages, name: name })}
      </div>
    );
  };

  const buildPacingTableHeader = ({ columns }) => {
    const targets = columns.slice(1);
    return (
      <tr>
        <th className="pacingHeader">{columns[0].Header}</th>
        {targets.map((target, index) => (
          <th className="pacingHeader middle" key={index}>
            {target.Header}
          </th>
        ))}
      </tr>
    );
  };

  const buildPacingTableRow = ({ columns, row, index, total, isTotal }) => {
    if (isTotal) {
      return (
        <tr className="total-row" key="total-row">
          <td>Total Private Markets</td>
          {total.map((value, colIndex) => (
            <td className={'middle'} key={colIndex}>
              {value}
            </td>
          ))}
        </tr>
      );
    }

    return (
      <tr key={index}>
        <td>{row[columns[0].accessor]}</td>
        {columns.slice(1).map((column, colIndex) => {
          // Update the total array
          total[colIndex] += row[column.accessor];
          return (
            <td className={'middle'} key={colIndex}>
              {row[column.accessor]}
            </td>
          );
        })}
      </tr>
    );
  };

  const buildHeader = (index) => {
    const name = allNames[index];
    return name && `${name} Portfolio`;
  };

  const buildPacingTable = ({ columns, rows, name }) => {
    const total = Array(columns.length - 1).fill(0);
    return (
      <div className="pacing-item">
        <div className="pacing-title">
          <div class="itemBtnExpanded itemBtn d-flex   align-items-center justify-content-between">
            <div class="form-icon align-items-center">
              <span class="menuLblHead">{`Pacing Plan - ${name}`}</span>
            </div>
          </div>
        </div>
        <div className="accordion-content">
          <div className="scrollable-x scrollable">
            <div className="DD-table-container">
              <table className="pacingTable">
                <thead>{buildPacingTableHeader({ columns })}</thead>
                <tbody>
                  {rows.map((value, index) =>
                    buildPacingTableRow({
                      row: value,
                      index,
                      columns,
                      total: total,
                      isTotal: false,
                    })
                  )}
                  {buildPacingTableRow({
                    columns,
                    total: total,
                    index: rows.length,
                    row: {},
                    isTotal: true,
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const generateColors = (numColors) => {
    const colors = ['#418ab3', '#a6b727', '#f69200', '#838383', '#fec306'];
    const ret = [];
    for (let i = 0; i < numColors; i++) {
      const color = colors[i % colors.length];
      ret.push(color);
    }
    return ret;
  };

  const buildPacingChart = ({ data, name }) => {
    const chartData = {
      series: data.map((item) => item.percentage), // Array of percentages
      options: {
        chart: {
          type: 'donut',
        },
        labels: data.map((item) => `${item.asset_class}, ${item.percentage}%`), // Labels with asset_class and percentage
        legend: {
          show: false, // Remove the legend
        },
        dataLabels: {
          enabled: true, // Enable data labels on the chart
          dropShadow: {
            enabled: false, // Disable shadows to keep labels crisp
          },
          style: {
            fontSize: '10px', // Adjust font size to ensure it fits within the sections
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#fff'], // Set the label color to white to contrast with the background
          },
          background: {
            enabled: true,
            foreColor: '#000', // Text color inside the box (black)
            borderRadius: 3, // Rounded box corners
            padding: 4, // Padding inside the white box
            dropShadow: {
              enabled: true,
              top: 1,
              left: 1,
              blur: 1,
              color: '#fff', // Shadow color for better contrast
              opacity: 0.5,
            },
          },
          formatter: function (val, opts) {
            // Use the same labels defined in the chart options
            return `${opts.w.globals.labels[opts.seriesIndex]}`;
          },
          textAnchor: 'middle', // Center-align the text
          position: 'inside', // Ensure labels stay inside the chart sections
          fit: true, // Make sure the text fits within the slices
        },
        colors: generateColors(data.length),
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: false, // Disable changing the name on hover
                },
                value: {
                  show: true, // Disable changing the value on hover
                  fontWeight: 600,
                  formatter: function (w) {
                    // Keep total percentage displayed, unaffected by hover
                    return `${w}%`;
                  },
                },
                total: {
                  show: true,
                  fontSize: '22px',
                  fontWeight: 600,
                  color: '#373d3f', // Customize text color
                  label: 'Total', // Optional label before the percentage
                  formatter: function (w) {
                    // Keep total percentage displayed, unaffected by hover
                    return `${w.globals.seriesTotals.reduce((a, b) => a + b, 0).toFixed(1)}%`;
                  },
                },
              },
            },
          },
        },
      },
    };

    return (
      <div className="pacing-item">
        <div className="pacing-title">
          <div class="itemBtnExpanded itemBtn d-flex   align-items-center justify-content-between">
            <div class="form-icon align-items-center">
              <span class="menuLblHead">{`Allocation Snapshot - ${name}`}</span>
            </div>
          </div>
        </div>
        <div className="accordion-content allocation-snapshot">
          <div>
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="donut"
              width="100%"
            />
          </div>
        </div>
      </div>
    );
  };

  return allColumns.map((element, index) => (
    <div>
      <div class="fund-wrap-web m-2 mt-2 p-3">
        <p class="web-header">{buildHeader(index)}</p>
      </div>
      <div className="fund-wrap-content m-2 mt-2 p-3">
        <div className="d-flex  justify-content-between align-items-center">
          <div className="recomandation-content">{buildPacingRow(index)}</div>
        </div>
      </div>
    </div>
  ));
}

export default PdfPortfolios;
